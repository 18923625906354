import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { storage as LocalStorage } from "services/config/storage";

// import { Empty } from "antd";
import ImageGallery from "react-image-gallery";
import { ReactReader } from "react-reader";
import CloseBlackIcon from 'assets/image/close-black.svg';
import Timer from 'components/Timer';
import "./MediaViewer.scss";
import moment from 'moment';
import * as actions from '../../store/story/actions';
import PdfView from "./PdfView";
import ListenStory from "./ListenStory";

const TEXT = 'text';
const AUDIO = 'audio';
const HTML = 'html';
const IMAGE = 'image';
const PDF = 'pdf';
const EPUB = 'epub';
const VIDEO = 'video';
const YOUTUBE = 'youtube';

class MediaViewer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      total_time: 0,
      timerVisible: true,
      currentTime: new Date(),
      minutes: 0,
      seconds: 0,
    };

    this.interval = null;
  }
  setTotalTime = (time) => {
    this.setState({
      total_time: time,
    });
  };
  getYoutubeId = (url) => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);

    if (match && match[2].length === 11) {
      return match[2];
    } else {
      return "error";
    }
  };
  componentWillUnmount = async () => {
    // await this.props.setTotalTime(this.state.total_time);
    clearInterval(this.interval);
  };

  closeModal = async () => {
    console.log("closeModal");
    this.setState({ timerVisible: false });
    this.props.setTotalTime(this.state.total_time);
    const isoFormattedTime = moment(this.state.currentTime).toISOString();
    const payload = {
      story: this.props.data?.section_data[0].story,
      session_time: this.state.total_time,
      started_at: isoFormattedTime,
      device_id: LocalStorage.fetch.deviceId()
        ? LocalStorage.fetch.deviceId()
        : LocalStorage.fetch.parentData()?.contact_no?.toString(),
    };
    try {
      const api_resp = await this.props.updateStoryTrackingData(payload);
      // Further handling of the response
    } catch (error) {
      console.error("Error occurred:", error);
      // Handle the error
    }

    await this.props.closeMedia();
  };

  simpleClose = async () => {
    await this.props.closeMedia();
  };
  setTime = (time) => {
    // calculate min and seconds from time
    let minutes = Math.floor(time / 60);
    let seconds = time - minutes * 60;
    this.setState({
      minutes: minutes,
      seconds: seconds,
    });
  };
  componentDidMount() {
    this.setTime(this.props.total_time);
  }

  render() {
    const { data,activityPage } = this.props;
    const firstMedia = activityPage ? data[0] : data?.section_data[0];

    const displayHeader = firstMedia.content_type === PDF;
    return (
      <div className="component-media-viewer">
        {this.state.timerVisible &&
          (window.location.href.includes("/story") ||
            window.location.href.includes("/Story") ||
            // window.location.href.includes("/guidedPath") ||
            window.location.href.includes("/collection")) && (
            <div
              id="header-div"
              style={{
                background: "#FFD23F",
                width: displayHeader ? "100%" : "100%",
                height: displayHeader ? "9.5%" : "9.5%",
                top: "0",
                left: "45px",
                // marginTop: window.innerWidth < 768 ? "5%" : "0",
                zIndex: "1000000",
                // marginLeft: "45px",
                // marginRight: "45px",
              }}
            >
              <div
                ref={this.timerRef}
                className={`timer ${this.state.timerFixed ? "fixed" : ""}`}
              >
                <Timer
                  minutes={parseInt(this.props.total_time / 60)}
                  seconds={
                    this.props.total_time -
                    parseInt(this.props.total_time / 60) * 60
                  }
                  setTotalTime={this.setTotalTime}
                  closeModal={this.closeModal}
                />
              </div>
            </div>
          )}

        {/* // if the url contains story then show it  */}
        {!(
          window.location.href.includes("story") ||
          window.location.href.includes("Story") ||
          // window.location.href.includes("guidedPath") ||
          window.location.href.includes("/collection")
        ) && (
          <img
            src={CloseBlackIcon}
            className="close-icon"
            alt="close"
            onClick={this.simpleClose}
          />
        )}

        {/* Text */}
        {firstMedia.content_type === TEXT && (
          <div
            dangerouslySetInnerHTML={{ __html: firstMedia.description }}
          ></div>
        )}
        {/* AUDIO FILES */}
        {firstMedia.media.length !== 0 && firstMedia.content_type === AUDIO && (
          <ListenStory
            url={firstMedia.media[0].media_url}
            imageUrl={data?.background_image}
            name={data?.name}
            description={data?.description}
            authorName={data?.author_name}
            preloadedAudio={this.props.preloadedAudio}
          />
        )}
        {/* HTML FILES */}
        {firstMedia.content_type === HTML && (
          <div dangerouslySetInnerHTML={{ __html: firstMedia.description }} />
        )}
        {/* IMAGE FILES */}
        {firstMedia.content_type === IMAGE && (
          <ImageGallery
            items={firstMedia.media.map((m) => ({
              original: m.media_url,
              thumbnail: m.media_url,
            }))}
            originalClass="img-class"
          />
        )}
        {/* PDF FILES */}
        {firstMedia.content_type === PDF && (
          // <iframe
          //   width="80%"
          //   height="100%"
          //   src={`https://docs.google.com/gview?url=${firstMedia.media[0].media_url}&embedded=true`}
          //   frameBorder="0"
          //   allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          //   title="pdf file view"
          // />
          // <div className="flip-book-container" src={firstMedia.media[0].media_url} />
          // <div id="pdf-view"></div>
          <PdfView
            url={firstMedia?.media[0].media_url}
            questions={this.props.questions}
            openSuccessModal={this.props.openSuccessModal}
            closeModal={this.closeModal}
          />
        )}
        {/* EPUB FILES */}
        {firstMedia.content_type === EPUB && (
          <ReactReader url={firstMedia.media[0].media_url} showToc />
        )}
        {/* VIDEO FILES */}
        {firstMedia.content_type === VIDEO && (
          <video controls>
            <source src={firstMedia.media[0].media_url} type="video/mp4" />
          </video>
        )}
        {/* YOUTUBE FILES */}
        {firstMedia.content_type === YOUTUBE && (
          <iframe
            width="80%"
            height="100%"
            src={`https://www.youtube.com/embed/${this.getYoutubeId(
              firstMedia.media[0].media_url
            )}`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="video"
          />
        )}
      </div>
    );
  }
}

// export default MediaViewer;

export default connect(
  ({  story,  }) => ({
    ...story,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        ...actions,
      },
      dispatch
    )
)(MediaViewer);
